<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

export default {
    data: () => {
        return {
            title: "Novo pedido",
            items: [
                {
                    text: "Genesis",
                    href: "/",
                },
                {
                    text: "pedidos",
                    active: true,
                },
            ],
            contratos: [
                {
                    id: 'e23t45y6u7i8o9p0',
                    created_at: '01/01/2021',
                    updated_at: '01/01/2021',
                    deleted_at: null,
                    cliente_id: 'e23t45y6u7i8o9p0',
                    validate_at: '01/01/2022',
                    status: 'ativo',
                    assinaturas: [
                        {
                            id: 1,
                            parte: 'locador',
                            nome: 'João da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                        {
                            id: 2,
                            parte: 'locatario',
                            nome: 'Maria da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                        {
                            id: 3,
                            parte: 'locatario',
                            nome: 'Joana da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                    ]

                },
                {
                    id: 'e23t45y6u7i8o9p0',
                    created_at: '01/01/2021',
                    updated_at: '01/01/2021',
                    validate_at: '01/01/2022',
                    deleted_at: null,
                    cliente_id: 'e23t45y6u7i8o9p0',
                    status: 'pendente',
                    assinaturas: [
                        {
                            id: 1,
                            parte: 'locador',
                            nome: 'João da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'pendente',
                            validate: 'pendente',
                        },
                        {
                            id: 2,
                            parte: 'locatario',
                            nome: 'Maria da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'pendente',
                            validate: 'pendente',
                        },
                        {
                            id: 3,
                            parte: 'locatario',
                            nome: 'Joana da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                    ]

                },
                {
                    id: 'e23t45y6u7i8o9p0',
                    created_at: '01/01/2021',
                    updated_at: '01/01/2021',
                    validate_at: '01/01/2022',
                    deleted_at: null,
                    cliente_id: 'e23t45y6u7i8o9p0',
                    status: 'vencido',
                    assinaturas: [
                        {
                            id: 1,
                            parte: 'locador',
                            nome: 'João da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                        {
                            id: 2,
                            parte: 'locatario',
                            nome: 'Maria da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                        {
                            id: 3,
                            parte: 'locatario',
                            nome: 'Joana da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                    ]

                },
                {
                    id: 'e23t45y6u7i8o9p0',
                    created_at: '01/01/2021',
                    updated_at: '01/01/2021',
                    validate_at: '01/01/2022',
                    deleted_at: null,
                    cliente_id: 'e23t45y6u7i8o9p0',
                    status: 'cancelado',
                    assinaturas: [
                        {
                            id: 1,
                            parte: 'locador',
                            nome: 'João da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                        {
                            id: 2,
                            parte: 'locatario',
                            nome: 'Maria da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                        {
                            id: 3,
                            parte: 'locatario',
                            nome: 'Joana da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                    ]

                },
            ]
        }
    },
    components: {Layout, PageHeader},
}
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h1>{{ title }}</h1>
                    </div>
                </div>

                <br>
                <br>


            </div>
        </div>


        <div class="row">
            <div class="col-8">
                <div class="card">
                    <div class="card-body">
                        <h3>Livros disponíveis</h3>

                        <div class="row">
                            <div class="col">
                                <div class="card">
                                    <div class="card-body">
                                        <img src="@/assets/images/livro_3.png" width="200" alt="" class="float-left mr-5" style="margin-bottom: 40px">
                                        <h2>1 ano - Livro 1 - Ensino fundamental</h2>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi iaculis placerat laoreet.</p>
                                        <p><strong class="fa-2x">Valor:</strong> <span class="fa-3x">R$ 100,00</span></p>


                                        <div class="add-box">
                                            <strong class="fa-2x">Quantidade: </strong>
                                            <span class="add menos">-</span>
                                            <input type="text" placeholder="1" value="1">
                                            <span class="add mais">+</span>
                                        </div>
                                        <button class="btn btn-primary">Adicionar ao carrinho</button>

                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card">
                                    <div class="card-body">
                                        <img src="@/assets/images/livro_4.png" width="200" alt="" class="float-left mr-5" style="margin-bottom: 40px">
                                        <h2>2 ano - Livro 1 - Ensino fundamental</h2>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi iaculis placerat laoreet.</p>
                                        <p><strong class="fa-2x">Valor:</strong> <span class="fa-3x">R$ 100,00</span></p>


                                        <div class="add-box">
                                            <strong class="fa-2x">Quantidade: </strong>
                                            <span class="add menos">-</span>
                                            <input type="text" placeholder="1" value="1">
                                            <span class="add mais">+</span>
                                        </div>
                                        <button class="btn btn-primary">Adicionar ao carrinho</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="card">
                                    <div class="card-body">
                                        <img src="@/assets/images/livro_1.png" width="200" alt="" class="float-left mr-5" style="margin-bottom: 40px">
                                        <h2>4 ano - Livro 1 - Ensino fundamental</h2>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi iaculis placerat laoreet.</p>
                                        <p><strong class="fa-2x">Valor:</strong> <span class="fa-3x">R$ 100,00</span></p>


                                        <div class="add-box">
                                            <strong class="fa-2x">Quantidade: </strong>
                                            <span class="add menos">-</span>
                                            <input type="text" placeholder="1" value="1">
                                            <span class="add mais">+</span>
                                        </div>
                                        <button class="btn btn-primary">Adicionar ao carrinho</button>

                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card">
                                    <div class="card-body">
                                        <img src="@/assets/images/livro_2.png" width="200" alt="" class="float-left mr-5" style="margin-bottom: 40px">
                                        <h2>5 ano - Livro 1 - Ensino fundamental</h2>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi iaculis placerat laoreet.</p>
                                        <p><strong class="fa-2x">Valor:</strong> <span class="fa-3x">R$ 100,00</span></p>


                                        <div class="add-box">
                                            <strong class="fa-2x">Quantidade: </strong>
                                            <span class="add menos">-</span>
                                            <input type="text" placeholder="1" value="1">
                                            <span class="add mais">+</span>
                                        </div>
                                        <button class="btn btn-primary">Adicionar ao carrinho</button>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card">
                    <div class="card-body">
                        <h3>Itens do novo pedido</h3>
                        <br><hr><br>

                        <div class="card">
                            <div class="card-body">
                                <img src="@/assets/images/livro_1.png" width="70" alt="" class="float-left mr-3">
                                <h3>4 ano - Livro 1 - Ensino fundamental</h3>
                                <div class="row">
                                    <div class="col"><br><p><strong class="fa-1x">Valor:</strong> <span class="fa-2x">R$ 100,00</span></p></div>
                                    <div class="col">
                                        <div class="add-box">
                                            <span class="add menos">-</span>
                                            <input type="text" placeholder="1" value="20">
                                            <span class="add mais">+</span>
                                        </div>
                                    </div>
                                    <div class="col-1">
                                        <br><button class="btn btn-outline-danger"><i class="fa fa-trash"></i></button>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <img src="@/assets/images/livro_2.png" width="70" alt="" class="float-left mr-3">
                                <h3>5 ano - Livro 1 - Ensino fundamental</h3>
                                <div class="row">
                                    <div class="col"><br><p><strong class="fa-1x">Valor:</strong> <span class="fa-2x">R$ 100,00</span></p></div>
                                    <div class="col">
                                        <div class="add-box">
                                            <span class="add menos">-</span>
                                            <input type="text" placeholder="1" value="10">
                                            <span class="add mais">+</span>
                                        </div>
                                    </div>
                                    <div class="col-1">
                                        <br><button class="btn btn-outline-danger"><i class="fa fa-trash"></i></button>
                                    </div>
                                </div>


                            </div>
                        </div>


                        <button class="btn btn-success btn-block btn-lg">Finalizar pedido</button>


                    </div>
                </div>
            </div>
        </div>

    </Layout>
</template>

<style scoped lang="scss">
.add-box {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;

    .add {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border: 1px solid #ccc;
        border-radius: 50%;
        cursor: pointer;
        margin: 0 10px;
    }

    input {
        width: 50px;
        height: 50px;
        text-align: center;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 5px;
    }
}

</style>
